import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPen, FaEdit, FaTrash, FaPlus, FaEye } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';

function CustomersList() {
  const [error, setError] = useState(null);
  const [customer, setCustomer] = useState([]);
  const { token, auth } = useToken();

  function deleteCustomer(id) {
    axios
      .delete(`${config.deleteUrl}/customer/${id}`, { params: { id: id } })
      .then((response) => {
        setCustomer((customer) => customer.filter((x) => x.id !== id));
      });
  }
  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/customers`).then((res) => {
      setCustomer(res.data);
    });
  }, []);

  return (
    <div className="col-lg-12 mt-5 box-sh p-3">
      <Link className="btn lpbgColor" to="/customerslist/addcustomer">
        Add Customer
      </Link>
      <table className="table mt-3">
        <thead>
          <tr>
            <th scope="col" className="w-auto">
              Name
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {customer.map((i) => (
            <tr key={i.id}>
              <td className="w-auto">{i.name}</td>
              <td>
                <Link
                  className="text-primary mr-2"
                  to={`/customer/editCustomer/${i.id}`}
                >
                  <FaPen />
                </Link>
                <a onClick={() => deleteCustomer(i.id)} className="text-danger">
                  <FaTrash />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CustomersList;
