import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    borderRadius: theme.spacing(4),
  },
}));

const AddLabel = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();
  const isAddMode = !id;
  const { register, reset, handleSubmit, setValue, errors } = useForm();
  const fileInput = createRef();

  function onSubmit(data) {
    return isAddMode ? createLabel(data) : updateLabel(id, data);
  }
  function createLabel(data) {
    const fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    fd.append('image', file, fileName);

    axios
      .post(`${config.postUrl}/label`, fd)
      .then((res) => {
        setLogo(res.data.logoSource);
        // history.push('.');
      })
      .catch((error) => {});
  }
  function updateLabel(id, data) {
    const fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    fd.append('image', file, fileName);

    axios
      .put(`${config.putUrl}/label/${id}`, fd)
      .then((res) => {
        history.push('..');
      })
      .catch((error) => {});

    // fetch(`${config.putUrl}/label/${id}`, requestOptions).then((res) => {
    //   setLogo(res.data.logoSource);
    //   history.push('..');
    // });
  }
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [logo, setLogo] = useState();
  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/label/${id}`).then((label) => {
        const fields = Object.keys(label.data);
        setLogo(label.data.logoSource);
        fields.map((field) => setValue(field, label.data[field]));
      });
    }
  }, []);

  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-3">
            {isAddMode ? 'Add Label' : 'Edit Label'}
          </h3>
          <div className="col-lg-12 d-flex justify-content-center">
            <img src={logo} className={classes.large}/>
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Label</label>
              <input
                type="text"
                className="form-control"
                placeholder="Label Name"
                name="name"
                ref={register({ required: true })}
              ></input>
              {errors.name && <span className="errorMessage">Pflichtfeld</span>}
            </div>
          </div>
          <div className="col-lg-12 mt-3 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Logo</label>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    id="labelInput" 
                    type="file"
                    className="custom-file-input "
                    name="logoFile"
                    ref={fileInput}
                    onChange={saveFile}
                  />
                  <label className="custom-file-label" for="labelInput">
                    Choose Photo
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 form-group mt-5 d-flex justify-content-center">
            <input type="submit" className="nav-link btn lpbgColor"></input>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddLabel;
