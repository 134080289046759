import { useState } from 'react';
import axios from 'axios';
function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

function getName() {
  const nameString = sessionStorage.getItem('token');
  const userName = JSON.parse(nameString);
  return userName?.username;
}
const getAuth = () => {
  const auth = (axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${getToken()}`);
  return getToken() ? auth : null;
};
export default function useToken() {
  //const [token, setTokeni] = useState();
  //const fullname = useState(getName());
  //const auth = useState(getAuth());

  const saveToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    //setTokeni(userToken.token);
  };

  const removeToken = () => {
    sessionStorage.removeItem('token');
  };

  return {
    setToken: saveToken,
    removeToken: removeToken,
    //setName:saveName,
    fullname: getName(),
    //token: token,
    auth: getAuth(),
  };
}
