import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import UserIcon from '@material-ui/icons/Person';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <Link to="/productslist" className="link">
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>
    <Link to="/productslist" className="link">
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Stammdaten" />
      </ListItem>
    </Link>
    <Link to="/runs" className="link">
      <ListItem button>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Runs" />
      </ListItem>
    </Link>
    <Link to="/customerslist" className="link">
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Kunden" />
      </ListItem>
    </Link>
    <Link to="/userList" className="link">
      <ListItem button>
        <ListItemIcon>
          <UserIcon />
        </ListItemIcon>
        <ListItemText primary="Benutzer" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset></ListSubheader>
    <Link to="/categories" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Warenstruktur" />
      </ListItem>
    </Link>
    <Link to="/distributor" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Verteiler" />
      </ListItem>
    </Link>
    <Link to="/brands" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Marken" />
      </ListItem>
    </Link>
    <Link to="/units" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Einheiten" />
      </ListItem>
    </Link>
    <Link to="/label" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Labels" />
      </ListItem>
    </Link>
    <Link to="/manufacturer" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Hersteller" />
      </ListItem>
    </Link>
    <Link to="/origin" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Herkunft" />
      </ListItem>
    </Link>
  </div>
);

export const thirdListItems = (
  <div>
    <ListSubheader inset></ListSubheader>
    <Link to="/reports" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </Link>
    {/* <Link to="/reporttest" className="link">
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Reports Test" />
      </ListItem>
    </Link> */}
  </div>
);