import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';

function Basic(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="card col-2 ml-1 mt-3">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} type="file" onChange={props.saveFile} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>

      <div className="card-body d-flex justify-content-center"></div>
    </div>
  );
}

const AddProductPhotos = (props) => {
  const { id, idProduct } = useParams();
  let history = useHistory();
  const { register, reset, handleSubmit, setValue, errors } = useForm();
  const fileInput = createRef();

  function onSubmit(e) {
    const fd = new FormData();
    fd.append('IdProduct', id);
    fd.append('image', e.target.files[0], e.target.files[0].name);

    axios
      .post(`${config.postUrl}/productphotos`, fd)
      .then((res) => {
        var auth = auth;
        axios.get(`${config.getUrl}/productphotos/${id}`).then((photos) => {
          setPhotos(photos.data);
        });
      })
      .catch((error) => {});
  }

  function deletePhoto(id) {
    axios
      .delete(`${config.deleteUrl}/productphoto/${id}`, { params: { id: id } })
      .then((response) => {
        setPhotos((product) => product.filter((x) => x.id !== id));
      });
  }
  function addProfilePhoto(id) {
    axios
      .put(`${config.putUrl}/productProfilePhoto/${id}`, { params: { id: id, idProduct: idProduct} })
      .then((response) => {
        setPhotos((product) => product.filter((x) => x.id !== id));
      });
  }

  const [prId, setPrId] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    setPrId(id);
    var auth = auth;
    axios.get(`${config.getUrl}/productphotos/${id}`).then((photos) => {
      setPhotos(photos.data);
    });
  }, []);

  return (
    <>
      <main className="box-sh p-3">
        <form>
          <input name="idProduct" type="hidden" value={prId} ref={register()} />
          <h3 className="text-center mb-3">Product Photos</h3>
          <div className="row d-flex  mt-5">
            {photos.map((i) => (
              <div className="card ml-3 card o-hidden border-0 shadow-sm col-2">
                <img
                  className="card-img-top mt-3"
                  src={i.logoSource}
                  alt="Card image cap"
                />
                <div className="card-body p-0 mt-2">
                  <p className="d-flex justify-content-center">
                  <a
                  id = {i.idProduct}
                   onClick={() => addProfilePhoto(i.id)}> 
                  { Boolean(i.isProfilePic === true) ? 'Standardbild' : 'Als Standardbild festlegen'}  </a>
                  </p>
                  <p className="d-flex justify-content-center">
                  <a onClick={() => deletePhoto(i.id)} className="text-danger">
                    <FaTrash />
                  </a>
                  </p>
                </div>
              </div>
            ))}
            <label
              for="file-upload"
              className="col-2 ml-1 mt-3 custom-file-upload"
            >
              <input
                id="file-upload"
                type="file"
                name="logoFile"
                ref={fileInput}
                //onChange={saveFile}
                onChange={onSubmit}
              />
              Add Photo
            <FaPlus />
            </label>
            {}
          </div>
        </form>
      </main>
    </>
  );
};

export default AddProductPhotos;
