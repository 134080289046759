import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(80),
    height: theme.spacing(40),
    borderRadius: theme.spacing(4),
  },
}));

const AddDistributor = (props) => {
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();
  const isAddMode = !id;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    errors,
  } = useForm();

  const fileInput = createRef();
  const [logo, setLogo] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  function onSubmit(data) {
    return isAddMode ? createDistributor(data) : updateDistribuor(id, data);
  }
  function createDistributor(data) {
    const fd = new FormData();
    for (var key in data) {
      fd.append(key, data[key]);
    }
    fd.append('image', file, fileName);

    axios
      .post(`${config.postUrl}/distributor`, fd)
      .then((res) => {
        setLogo(res.data.logoPath);
        history.push('.');
      })
      .catch((error) => {});
  }

  function updateDistribuor(id, data) {
     if (file != null) {
        const fd = new FormData();
        for (var key in data) {
        fd.append(key, data[key]);
        }
        fd.append('image', file, fileName);
      axios
      .put(`${config.putUrl}/distributor/${id}`, fd)
      .then((res) => {
        // setLogo(res.data.logoPath);
        history.push('.');
      })
      .catch((error) => {});
     }
     else {
      axios
      .put(`${config.putUrl}/distributor/${id}`, data)
      .then((res) => {
        // setLogo(res.data.logoPath);
        history.push('.');
      })
      .catch((error) => {});
     }
  }

  const saveFile = (e) => {
    //console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/distributor/${id}`).then((res) => {
        const fields = Object.keys(res.data);
        setLogo(res.data.logoPath);
        fields.map((field) => setValue(field, res.data[field]));
      });
    }
  }, []);

  return (
    <>
      <main className="box-sh p-3">
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-5">
            {isAddMode ? 'Add Distributor' : 'Edit Distributor'}
          </h3>
            <div className="col-lg-12 d-flex justify-content-center">
            <img src={logo} className={classes.large}/>
          </div>
            <div className="col-lg-12 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Distributor</label>
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
                className="form-control"
                placeholder="Distributor Name"
              ></input>
              {errors.name && <span className="errorMessage">Pflichtfeld</span>}
            </div>
            </div>
            <div className="col-lg-12 mt-2 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Logo</label>
              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    id="distributor-logo"
                    type="file"
                    className="custom-file-input "
                    name="logoSource"
                    ref={fileInput}
                    onChange={saveFile}
                  />
                  <label className="custom-file-label" for="distributor-logo">
                    {fileName}
                  </label>
                </div>
              </div>
            </div>


          </div>
            <div className="col-lg-12 form-group mt-5 d-flex justify-content-center">
              <input type="submit" className="nav-link btn lpbgColor"></input>
            </div>
        </form>
      </main>
    </>
  );
};

export default AddDistributor;
