var config = {
  getUrl: 'https://api.lpmarktforschung.ch/api/get',
  postUrl: 'https://api.lpmarktforschung.ch/api/post',
  putUrl: 'https://api.lpmarktforschung.ch/api/put',
  deleteUrl: 'https://api.lpmarktforschung.ch/api/delete',
  authUrl: 'https://api.lpmarktforschung.ch/api/auth',
};

// var config = {
//   getUrl: 'https://localhost:44354/api/get',
//   postUrl: 'https://localhost:44354/api/post',
//   putUrl: 'https://localhost:44354/api/put',
//   deleteUrl: 'https://localhost:44354/api/delete',
//   authUrl: 'https://localhost:44354/api/auth',
// };


export default config;

