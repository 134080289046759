import { useHistory, useParams } from "react-router-dom";
import {Fragment, useEffect, useState, useMemo } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../config/config";
import axios from "axios";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import '../../App.css';
import {useLocation} from 'react-router-dom';


const AddProducts = (props) => {
  const { id } = useParams();
  let history = useHistory();
  const isAddMode = !id;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    value,
    errors,
    formState,
    control,
    setError,clearErrors
  } = useForm({
    defaultValues: useMemo(() => {
      return props;
    }, [props]),
  });
  const location = useLocation();
  
  function onSubmit(data) {
    data.labelId = data.labels;
    data.labels = "";
    data.action = aktionChecked;
    data.idDistributor = distributorId;
    return isAddMode ? createProduct(data) : updateProduct(id, data);
  }
  function createProduct(data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        body: JSON.stringify({ data }),
      },
    };

    axios
      .post(`${config.postUrl}/products`, data, axiosConfig)
      .then((data) => {
        history.push(".");
      })
      .catch((error) => {});

  }
  function updateProduct(id, data) {
    let axiosConfig = {
      headers: {
        "Access-Control": "Allow-Headers",
        "Content-Type": "application/json",
      },
      body: {
        body: JSON.stringify({ data }),
      },
    };

    axios
      .put(`${config.putUrl}/product/${id}`, data)
      .then((data) => {
        history.push(".");
      })
      .catch((error) => {});
  }



  const [product, setProduct] = useState([]);

  const [distributor, setDistributor] = useState([]);
  const [distributorId, setDistributorId] = useState("0");

  const [mainCategory, setMainCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [label, setLabel] = useState([]);
  const [mainId, setMainId] = useState(0);
  const [catId, setCatId] = useState(0);
  const [subCatId, setSubCatId] = useState(0);
  const [einheitId, setEinheitId] = useState(0);
  const [aktionChecked, setAktionChecked] = useState(false);
  const [gluten, setGluten] = useState(false);
  const [lactose, setLactose] = useState(false);
  const [aktiv, setAktiv] = useState(false);
  const [checkedItems, setCheckedItems] = useState([label[1]]);
  var labels = [];


  function distributorChange(e) {
    setDistributorId(e.target.value);
    if(e.target.value != '0')
    {
      clearErrors("idDistributor");
      setValue("idDistributor", "0")
    }
    else{
      setValue("idDistributor", "")
      setError("idDistributor",{ shouldFocus: false });
    }
  }
  function mainKategorie(e) {
    setMainId(e.target.value);
    if(e.target.value != '0')
    {
      clearErrors("mainId");
      setValue("mainId", "0")
    }
    else{
      setValue("mainId", "")
      setError("mainId",{ shouldFocus: false });
    }
  }
  function Kategorie(e) {
    setCatId(e.target.value);
    if(e.target.value != '0')
    {
      clearErrors("catId");
      setValue("catId", '0')
    }
    else{
      setValue("catId", '')
      setError("catId",{ shouldFocus: false });
    }
  }

  const { state } = location;

  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      setTimeout(() => {
        axios.get(`${config.getUrl}/product/${id}`).then((res) => {
          const product = res.data.product;
          const fields = Object.keys(product);
          reset(product, fields);
          setMainId(res.data.mainCategory["id"]);
          setCatId(res.data.category["id"]);
          setValue("mainId", res.data.mainCategory.id)
          setValue("catId", res.data.category.id)
          setDistributorId(product["idDistributor"]);
          setSubCatId(product["idSubCategory"]);
          setEinheitId(product["idUnit"]);
          setGluten(product["gluten"]);
          setLactose(product["lactose"]);
          setAktiv(product["status"]);
          setAktionChecked(product["action"])
          product["buyDate"] = format(
            new Date(product["buyDate"]),
            "yyyy-MM-dd"
          );
          // if (product["action"] == true) setAktionChecked(!aktionChecked);
          product["labels"] = product.labels.map((pl) => pl.idLabel.toString());
          // product["labels"] = setCheckedItems([label[1]])

          // axios.get(`${config.getUrl}/getLabelsByDistributor/${id}`).then((res) => {
          //   setLabel(res.data);
          //   //console.log(res.data);
          // });
          
          setProduct(product);
          console.log(product)
          fields.forEach((field) =>
            setValue(field, product[field], {
              shouldValidate: false,
              shouldDirty: true,
            })
          );
          setProduct(product);
        }, 100);
      });
    }
    else{
    setDistributorId(state);
    }
  }, [product.id]);


  

  useEffect(() => {
    axios.get(`${config.getUrl}/mainCategory`).then((res) => {
      setMainCategory(res.data);
    });
    axios.get(`${config.getUrl}/category`).then((res) => {
      setCategory(res.data);
    });
    axios.get(`${config.getUrl}/subCategory`).then((res) => {
      setSubCategory(res.data);
    });
    axios.get(`${config.getUrl}/distributors`).then((res) => {
      setDistributor(res.data);
      //console.log(distributor.distributorLabel);
    });

    axios.get(`${config.getUrl}/brand`).then((res) => {
      setBrand(res.data);
      //console.log(res.data);
    });
    axios.get(`${config.getUrl}/unit`).then((res) => {
      setUnit(res.data);
      //console.log(res.data);
    });
    axios.get(`${config.getUrl}/origin`).then((res) => {
      setOrigin(res.data);
      //console.log(res.data);
    });
    axios.get(`${config.getUrl}/manufacturer`).then((res) => {
      setManufacturer(res.data);
      //console.log(res.data);
    });
    axios.get(`${config.getUrl}/joinlabels`).then((res) => {
      setLabel(res.data);
      //console.log(res.data);
    });
  }, []);



  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? "" : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-3">
            {isAddMode ? "Add Product" : "Edit Product"}
          </h3>

          <div className="col-lg-12 row">
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="idDistributor"
                rules={{ required: errors.idDistributor  ? false : true }}
                render={({ onChange, value }) => (
                  <TextField
                  className="w-100"
                    id="Distributor"
                    select
                    disabled={isAddMode ? false : true}
                    label="Distributor"
                    value={distributorId}
                    onChange={distributorChange}
                    error={errors.idDistributor}
                    helperText = {errors.idDistributor ? "Pflichtfeld" : ""}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                    <option value="0">Bitte auswählen</option>
                    {distributor.map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="mainId"
                rules={{ required: errors.mainId  ? false : true }}
                render={({ onChange, value }) => (
                  <TextField
                    id="Haupt-Kategorie"
                    select
                    className="w-100"
                    label="Haupt-Kategorie"
                    value={mainId}
                    onChange={mainKategorie}
                    error={errors.mainId}
                    helperText = {errors.mainId ? "Pflichtfeld" : ""}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option value="0">Haupt-Kategorie auswählen</option>
                    {mainCategory.map((mc) => (
                      <option key={mc.id} value={mc.id}>
                        {mc.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="catId"
                rules={{ required: errors.catId  ? false : true }}
                render={({ onChange, value }) => (
                  <TextField
                    id="kategorie"
                    select
                    className="w-100"
                    label="Kategorie"
                    value={catId}
                    onChange={Kategorie}
                    error={errors.catId}
                    helperText = {errors.catId ? "Pflichtfeld" : ""}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option value="0">Kategorie auswählen</option>
                    {category
                      .filter((c) => c.idMainCategory == mainId)
                      .map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="idSubCategory"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <TextField
                    className="w-100"
                    id="unterKategorie"
                    name="idSubCategory"
                    select
                    label="Unter-Kategorie"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={errors.idSubCategory ? true : false}
                    helperText = {errors.idSubCategory ? "Pflichtfeld" : ""}
                    // required={value == "" || value == null ? true : false}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option value="0">Unter-Kategorie auswählen</option>
                    {subCategory
                      .filter((sc) => sc.idCategory == catId)
                      .map((sc) => (
                        <option key={sc.id} value={sc.id}>
                          {sc.name}
                        </option>
                      ))}
                  </TextField>
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-9 form-group">
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <FormControl variant="filled"  className="w-100">
                    <TextField
                      id="name"
                      label="Name"
                      value={value}
                      error={errors.name}
                      helperText = {errors.name ? "Pflichtfeld" : ""}
                      onChange={(event) => onChange(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                )}
                
              />
            </div>
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="eanCode"
                render={({ onChange, value }) => (
                  <FormControl className="w-100">
                    <TextField
                      id="eanCode"
                      value={value}
                      label="EAN-Code"
                      onChange={(event) => onChange(event.target.value)}
                      // defaultValue={0}
                      // ref={register({ required: true })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      // startAdornment={<InputAdornment position="start"></InputAdornment>}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-12 form-group">
              <Controller
                control={control}
                name="description"
                render={({ onChange, value }) => (
                  <TextField
                    id="description"
                    label="Bezeichnung"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    // defaultValue=""
                    fullWidth
                    multiline
                    rows={4}
                    // ref={register({ required: true })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="defaultQuantity"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <FormControl  className="w-100">
                    <TextField
                      id="standardMenge"
                      label="Standard Menge"
                      type="number"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      error={errors.defaultQuantity}
                      helperText = {errors.defaultQuantity ? "Pflichtfeld" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
              <Controller
                control={control}
                name="quantity"             
                rules={{ required: true }}  
                render={({ onChange, value }) => (
                  <FormControl  className="w-100">
                    <TextField
                      id="verkaufsmenge"
                      type="number"
                      label="Verkaufsmenge"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      error={errors.quantity}
                      helperText = {errors.quantity ? "Pflichtfeld" : ""}
                      // defaultValue={0}
                      // ref={register({ required: true })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                control={control}
                name="idUnit"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <TextField
                    className="w-100"
                    id="idUnit"
                    select
                    label="Einheit"
                    defaultValue={value}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={errors.idUnit}
                    helperText = {errors.idUnit ? "Pflichtfeld" : ""}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    <option value="0">Einheit auswählen</option>
                      {unit.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3">
              <Controller
                name="price"
                control={control}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <FormControl  className="w-100">
                    <TextField
                      id="regulärpreis"
                      type="number"
                      label="Regulärpreis"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      error={errors.price}
                      helperText = {errors.price ? "Pflichtfeld" : ""}
                      // ref={register}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">CHF</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-3 form-group">
            <Controller
                name="action"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value={aktionChecked ? true : false}
                        checked={aktionChecked ? true : false}
                        onChange={() => setAktionChecked(!aktionChecked)}
                        control={<Checkbox color="default" />}
                        label="Aktion"
                        labelPlacement="end"
                      />
                      </FormGroup>
                     {/* <Checkbox aria-label="Aktion" name="aktion" value={value} checked={aktionChecked}  onChange={() => setAktionChecked(!aktionChecked)}>
                      <FormControlLabel value="aktion"  checked={aktionChecked} control={<Checkbox />} label="Aktion" />
                    </Checkbox> */}
                  </FormControl>
                )}
              />
            </div>
            <div
              className="col-lg-3 form-group"
              hidden={aktionChecked ? "" : "hidden"}
            >
              <Controller
                control={control}
                name="aQuantity"
                render={({ onChange, value }) => (
                  <FormControl  className="w-100">
                    <TextField
                      id="aktionsmenge"
                      label="aktionsmenge"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                       
                      // ref={register}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                )}
              />
            </div>
            <div
              className="col-lg-3 form-group"
              hidden={aktionChecked ? "" : "hidden"}
            >
               <Controller
                name="aPrice"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl  className="w-100">
                    <TextField
                      id="Aktionspreis"
                      type="number"
                      label="Aktionspreis"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      // ref={register}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">CHF</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-3 form-group">
            <Controller
                name="buyDate"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl  className="w-100"> 
                    <TextField
                      id="kaufdatum"
                      type="date"
                      label="Kaufdatum"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      // ref={register}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                control={control}
                name="idBrand"
                render={({ onChange, value }) => (
                  <TextField
                  className="w-100"
                    id="idBrand"
                    select
                    label="Marke"
                    
                    // ref={register({ required: true })}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                   <option value="0">Marke auswählen</option>
                    {brand.map((b) => (
                      <option key={b.id} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                control={control}
                name="idOrigin"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <TextField
                  className="w-100"
                    id="herkunft"
                    select
                    label="Herkunft"
                    error={errors.idOrigin}
                    helperText = {errors.idOrigin ? "Pflichtfeld" : ""}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                      <option value="0">Herkunft auswählen</option>
                      {origin.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                control={control}
                name="idManufacturer"
                render={({ onChange, value }) => (
                  <TextField
                  className="w-100"
                    id="hersteller"
                    select
                    label="Hersteller"
                    
                    // ref={register({ required: true })}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                       <option value="0">Hersteller auswählen</option>
                        {manufacturer.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                  </TextField>
                )}
              />
            </div>
          </div>
          <div className="col-lg-12 mt-3 row">
            <div className="col-lg-3">
            <Controller
                name="gluten"
                control={control}
                render={({ onChange, value, ref }) => (
                  <FormControl>
                     <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value={value}
                        checked={value ? 1 : 0}
                        inputRef={register}
                        
                        onChange={(e) => onChange(e.target.checked)}
                        control={<Checkbox color="default" />}
                        label="Glutenfrei"
                        labelPlacement="end"
                      />
                      </FormGroup>
                    {/* <FormLabel component="legend">Glutenfrei</FormLabel>
                    <Checkbox aria-label="Glutenfrei" checked={gluten} value={value}  onChange={() => setGluten(!gluten)} >
                      <FormControlLabel checked={gluten} value={value}  onChange={() => setGluten(!gluten)} control={<Checkbox />} label="Glutenfrei" />
                    </Checkbox> */}
                  </FormControl>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                name="lactose"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value={value ? true : false}
                        checked={value ? 1 : 0}
                        
                        onChange={(e) => onChange(e.target.checked)}
                        control={<Checkbox color="default" />}
                        label="Lactosefrei"
                        labelPlacement="end"
                      />
                      </FormGroup>
                    {/* <FormLabel component="legend">Lactosefrei</FormLabel>
                    <Checkbox aria-label="Lactosefrei"   >
                      <FormControlLabel value={value} checked={value}   onChange={(e) => onChange(e.target.checked)} control={<Checkbox />} />
                    </Checkbox> */}
                  </FormControl>
                )}
              />
            </div>
            <div className="col-lg-3 form-group">
            <Controller
                name="status"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value={value ? true : false}
                        checked={value ? 1 : 0}
                        onChange={(e) => onChange(e.target.checked)}
                        control={<Checkbox color="default" />}
                        label="Aktiv"
                        labelPlacement="end"
                      />
                      </FormGroup>
                    {/* <FormLabel component="legend">Aktiv</FormLabel>
                    <Checkbox aria-label="Aktiv" checked={aktiv} value={value}  onChange={() => setAktiv(!aktiv)}>
                      <FormControlLabel   control={<Checkbox />} label="Aktiv" />
                    </Checkbox> */}
                  </FormControl>
                )}
              />
            </div>
            </div>
            <div className="row col-lg-12">
            <label className="ml-3">Labels</label>
              {label
                .filter(
                  (dl) => dl.distributorLabel.idDistributor == distributorId
                )
                .map((c, index) => {
                  const fieldName = `labels[${index}]`;
                  return (               
                  <fieldset name={fieldName} key={fieldName}>
                      <div className="checkbox">
                        <label>
                            <input
                            type="checkbox"
                            name="labels"
                            //defaultChecked="13"
                            ref={register}
                            value={c.distributorLabel.idLabel}
                          />
                          <span class="checkbox-material"><span className="check"></span></span>{c.label.name}
                        </label>
                       </div>
                    </fieldset>
                  );
                })}
            </div>
          <div className="col-lg-12 mt-3">
            <button
              type="submit"
              value="Submit"
              onClick={() => clearErrors("idDistributor")}
              className="btn btn-primary mt-5"
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Speichern
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddProducts;
