import Form from 'react-bootstrap/Form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';

const AddOrigin = (props) => {
  const { id } = useParams();
  let history = useHistory();
  const isAddMode = !id;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    errors,
    formState,
  } = useForm();

  
  const [origin, setOrigin] = useState([]);
  const [checked, setChecked] = useState(false);
  const { isValid } = useState([]);

  function onSubmit(data) {
    return isAddMode ? createOrigin(data) : updateOrigin(id, data);
  }
  function createOrigin(data) {
    axios
      .post(`${config.postUrl}/origin`, data)
      .then((res) => {
        history.push('.');
      })
      .catch((error) => {});
  }
  function updateOrigin(id, data) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    fetch(`${config.putUrl}/origin/${id}`, requestOptions).then((res) => {
      history.push('..');
    });
  }
  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/origin/${id}`).then((origin) => {
        const fields = Object.keys(origin.data);
        fields.map((field) => setValue(field, origin.data[field]));
      });
    }
  }, []);
  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-3">
            {isAddMode ? 'Add Origin' : 'Edit Origin'}
          </h3>

          <div className="col-lg-12 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Origin</label>
              <input type="text" className="form-control"
              name="name"
              ref={register({ required: true })}                          
              placeholder="Origin Name"
              ></input>
              {errors.name && (
                <span className="errorMessage">Pflichtfeld</span>
              )}
            </div>
            </div>
            {/* <div className="col-lg-12 form-group">
              <label>Status</label>
              <input type="checkbox"
              name="status"
              ref={register({ required: false })}                   
              className="checkbox"></input>
            </div> */}
            <div className="col-lg-12 mt-5 d-flex justify-content-center">
                <input type="submit" className="nav-link btn lpbgColor"></input>
            </div>
        </form>
      </main>
    </>
  );
};

export default AddOrigin;
