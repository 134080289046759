import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';

const AddMainCategory = (props) => {
  const { id } = useParams();
  let history = useHistory();
  const isAddMode = !id;
  const { register, reset, handleSubmit, setValue, errors } = useForm();
  const [category, setMainCategory] = useState();


  function onSubmit(data) {
    return isAddMode ? createMainCategory(data) : updateMainCategory(id, data);
  }

  function createMainCategory(data) {
   
      axios
      .post(`${config.postUrl}/maincategory`, data)
      .then((res) => {
        alert('test');
        history.push('.');
      })
      .catch((error) => {});
  }
  function updateMainCategory(id, data) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    fetch(`${config.putUrl}/label/${id}`, requestOptions).then((res) => {
      history.push('..');
    });
  }


  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/mainCategory/${id}`).then((label) => {
        const fields = Object.keys(label.data);
        setMainCategory(label.data.logoSource);
        fields.map((field) => setValue(field, label.data[field]));
      });
    }
  }, []);

  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-3">
            {isAddMode ? 'Add Main Categorie' : 'Edit Main Categorie'}
          </h3>
          <div className="col-lg-12 d-flex justify-content-center">
            {/* <Avatar alt="label" src={logo} className={classes.large} /> */}
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Main Category</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="name"
                ref={register({ required: true })}
              ></input>
              {errors.name && <span className="errorMessage">Pflichtfeld</span>}
            </div>
          </div>
          <div className="col-lg-12 form-group mt-5 d-flex justify-content-center">
            <input type="submit" className="nav-link btn lpbgColor"></input>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddMainCategory;
