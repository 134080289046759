import { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  FaPen,
  FaEdit,
  FaTrash,
  FaPlus,
  FaTable,
  FaImages,
} from 'react-icons/fa';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ReactTooltip from "react-tooltip";
import { useForm } from 'react-hook-form';
import imageAction from '../../aktion.svg' ; 
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const useStyles = makeStyles((theme) => ({
  extraSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  labelsmall: {
    width: theme.spacing(12),
    // height: theme.spacing(12),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  medium: {
    width: theme.spacing(20),
    height: theme.spacing(15),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    borderRadius: theme.spacing(4),
  },
}));
function ProductList() {
  const [error, setError] = useState(null);
  const [run, setRun] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [runId, setRunId] = useState(0);
  const [distId, setDistributorId] = useState(0);


  const [tableView, setTableView] = useState('');;
  const tabletest = () => {setTableView(''); setDetailTest('d-none');};

  const [detail, setDetailTest] = useState('d-none');;
  const detailtest = () => {setDetailTest(''); setTableView('d-none');};

  const [view, setView] = useState('col-lg-4');


  function viewProducts(e) {

    setView('col-lg-'+ e.target.value);
  }



  const {
    register,
    reset,
    handleSubmit,
  } = useForm();

  let history = useHistory();
  const [product, setProduct] = useState([]);
 

  const [date, setdDate] = useState([]);
  const [status, setStatus] = useState([]);
  const [labels, setLabels] = useState([]);
  const [productlabels, setProductlabels] = useState([]);
  const [productphotos, setProductphotos] = useState([]);
  const [filterRes, setFilterRes] = useState([]);
  const [unit, setUnit] = useState([]);
  const { token, auth } = useToken();
  const classes = useStyles();
  function deleteUser(id) {
    axios
      .delete(`${config.deleteUrl}/product/${id}`, { params: { id: id } })
      .then((response) => {
        setProduct((product) => product.filter((x) => x.id !== id));
      });
  }

  const memoizedUseMemo = useMemo(
    async () => {
       const res = await axios.get(`${config.getUrl}/products`);
      setProduct(res.data);
    },
    [],
 );

  const memoizedCallback = useCallback(
    () => {
        axios.get(`${config.getUrl}/products`).then((res) => {
          setProduct(res.data);
          // setFilterRes(res.data);
      })
    },
    [],
 );


  useEffect(() => {
    var auth = auth;
    memoizedCallback()
    axios.get(`${config.getUrl}/label`).then((res) => {
      setLabels(res.data);
    });
    axios.get(`${config.getUrl}/productlabel`).then((res) => {
      setProductlabels(res.data);
    });
    axios.get(`${config.getUrl}/productphotos`).then((res) => {
      setProductphotos(res.data);
    });
    axios.get(`${config.getUrl}/run`).then((res) => {
      setRun(res.data);
    });
    axios.get(`${config.getUrl}/unit`).then((res) => {
      setUnit(res.data);
    });
    axios.get(`${config.getUrl}/distributors`).then((res) => {
      setDistributor(res.data);
    });
  }, []);


// }
  function onChangeDistributor(e)
  {
    setProduct(product.filter(x => x.idDistributor == e.target.value))
    setDistributorId(e.target.value);
    var auth = auth;
    if(e.target.value > 0)
    {
      axios.get(`${config.getUrl}/productDist/${e.target.value}`).then((res) => {
        setProduct(res.data);
      });
    }
    else{
      memoizedCallback()
      // axios.get(`${config.getUrl}/products`).then((res) => {
      //   setProduct(res.data);
      //   //console.log(res.data.labels.idLabel);
      // });
    }
    axios.get(`${config.getUrl}/productlabel`).then((res) => {
      setProductlabels(res.data);
    });
    axios.get(`${config.getUrl}/productphotos`).then((res) => {
      setProductphotos(res.data);
    });
    axios.get(`${config.getUrl}/label`).then((res) => {
      setLabels(res.data);
    });
  }

  

    const searchItems = (searchValue) => {
        if (searchValue !== '' && searchValue !=null) {
            const filteredData = product.filter((item) => {
                return Object.values(item)
                    .join('')
                    .toLowerCase()
                    .includes(searchValue.toLowerCase());
            });
            setProduct(filteredData);
        } 
        else {
          if(distId > 0)
                {
                  axios.get(`${config.getUrl}/productDist/${distId}`).then((res) => {
                    setProduct(res.data);
                  });
           }
           else{
            memoizedCallback()
           }
        }
    };


  return (
    <>
    <div className="row col-lg-12 p-0 m-0 mt-3 border-bottom ">
              <div className="col-lg-3">
              <Link className="btn btn-light" 
              to={{pathname:"/productslist/addproduct",
              state: distId}}>
                  <FaPlus /> 
                    &nbsp; Neue Artikel erfassen
                </Link>
              </div>
              <div className="col-lg-3">
                <TextField 
                select
                label="Kanal"
                className="w-75"
                variant="outlined"
                SelectProps={{
                  native: true,
                }}
                margin="dense"
                onChange={onChangeDistributor}>
                  <option value="0"> Alles Kanäle </option>
                  {distributor.map((d) => (
                    <option value={d.id} key={d.id}> {d.name} </option>                 
                    ))}
                </TextField>
              </div>
                <div className="col-lg-3">  
                <TextField
                          type="text"
                          label="Search"
                          SelectProps={{
                            native: true,
                          }}
                          margin="dense"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          onChange={e => searchItems(e.target.value)}
                /> 
              </div>
              <div className={"col-lg-3 text-right "}>
              <button 
                onClick={tabletest}
                className={"btn btn-light mr-3 "}>
                    <FaTable />
                  </button>
                <button 
                onClick={detailtest}
                className="btn btn-light"><FaImages/> </button>
              </div>
    </div>
            <div className="row mt-5">
              <div  className={"col-lg-12 p-0 box-sh " +tableView}>
               <TableContainer component={Paper}>
                <Table className="table mb-0">
                  <TableHead>
                    <TableRow>
                      <TableCell scope="col">Foto</TableCell>
                      <TableCell scope="col">Artikel</TableCell>
                      <TableCell scope="col">Label</TableCell>
                      <TableCell scope="col">Preis</TableCell>
                      <TableCell scope="col"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {product.map((i) => (
                      <TableRow key={i.id}>
                        <TableCell > 
                          <img 
                          className={classes.medium}
                          src=
                                {productphotos.filter(pp => pp.idProduct == i.id && pp.isProfilePic == true).map(f => 
                                    f.logoSource
                                )}>
                            </img>
                        </TableCell>
                        <TableCell>
                        <Link
                            className="text-dark mr-2"
                            to={`/productslist/products/${i.id}`}
                          >
                          {i.name}
                        </Link>
                        <br/>
                        {i.description}
                        </TableCell>
                        <TableCell>
                              {productlabels.filter((plf) => plf.idProduct ==  i.id).map((pl) =>
                                  labels
                                    .filter((f) => f.id === pl.idLabel)
                                    .map((l) => (
                                      <img src={l.logoSource}
                                      className={classes.labelsmall}
                                      />
                                      // <Avatar
                                      //   alt="label"
                                      //   src={l.logoSource}
                                      //   className={classes.small, "mr-1"} 
                                      // />
                                    ))
                                )}
                          </TableCell>
                        <TableCell>
                          {i.quantity } {unit.filter(u => u.id == i.idUnit).map(up => up.name)}
                          <br/>
                          {i.price.toFixed(2)}
                          <br/>
                          {i.defaultQuantity} {unit.filter(u => u.id == i.idUnit).map(up => up.name)} = { (i.defaultQuantity * i.price / i.quantity).toFixed(2) }
                        </TableCell>
                        <TableCell className="text-right">
                        <ReactTooltip />
                          <Link
                            className="text-dark mr-2"
                            to={`/productslist/editproduct/${i.id}`}
                          >
                            <FaPen 
                              data-tip="Artikel bearbeiten "
                            />
                          </Link>
                          <Link
                            className="text-dark mr-2"
                            to={`/productslist/addproductphotos/${i.id}`}
                          >
                            <FaImages 
                              data-tip="Bilder bearbeiten "
                            />
                          </Link>
                          <a onClick={() => deleteUser(i.id)} className="text-dark">
                            <FaTrash 
                            data-tip="Löschen"
                            to="/#"
                            />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </TableContainer>
              </div>
              <div className={"col-lg-12 row mb-3 " +detail}>
                  <select className="form-control w-10" onChange={viewProducts}> 
                      <option value="6"> 2 </option>
                      <option value="4" selected> 3 </option>
                      <option value="3"> 4 </option>
                    </select>
                  {/* <button onClick={viewFirst} className="btn btn-light mr-2">2</button>
                  <button onClick={viewSecond} className="btn btn-light mr-2">3</button>
                  <button onClick={viewThird} className="btn btn-light">4</button> */}
            </div>
              <div className={"col-lg-12 p-0 row " +detail} >
                {product.map((p) => (
                  <>
                  <div className={"col-12 col-sm-12 col-md-6 " +view}>
                    <div className="card mb-3 p-0 o-hidden border-0 shadow-sm">
                    <div className="card-body ">
                    <div className="row d-flex justify-content-end">
                    <ReactTooltip />
                      <Link
                            className="text-dark mr-2"
                            to={`/productslist/editproduct/${p.id}`}
                          >
                            <FaEdit 
                              data-tip="Artikel bearbeiten "
                            />
                    </Link>
                    <Link
                            className="text-dark mr-2"
                            to={`/productslist/addproductphotos/${p.id}`}
                          >
                            <FaImages 
                                data-tip="Bilder bearbeiten "
                            />
                    </Link>
              
                      </div>
                      <div className="row d-flex justify-content-center">

                    
                      <img 
                        className={classes.large}
                        src=
                        {productphotos.filter(pp => pp.idProduct == (runId > 0 ? p.idProduct : p.id) && pp.isProfilePic == true).map(f => 
                            f.logoSource
                        )}
                          />
                      </div>
                          <Link 
                            className="lp-color mb-5 d-flex justify-content-center font-weight-bold"
                            to={`/productslist/products/${p.id}`}
                          >
                          {p.name}
                          </Link>
                        <div className="row mb-5 d-flex justify-content-center"> 
                            {productlabels.filter((plf) => plf.idProduct == (runId > 0 ? p.idProduct : p.id)).map((pl) =>
                            labels
                              .filter((f) => f.id === pl.idLabel)
                              .map((l) => (
                                <Avatar
                                  alt="label"
                                  src={l.logoSource}
                                  className={classes.small, "mr-1"} 
                                />
                              ))
                          )}
                        </div>
                      <div className="row d-flex justify-content-center">
                      <p>
                        {
                          (p.action) ?  
                          <div className="row mr-5">
                              <span className="font-weight-bold"> 
                                <span className="mr-2 text-custom">{p.aPrice} .- CHF</span>
                                <img className={classes.extraSmall} src={imageAction}/> 
                              </span>
                              </div>
                          : ""
                        }
                        </p> 
                          {
                            (p.action) ? 
                            <p className="pt-1 font-weight-bold text-center"> <span className="font-weight-bold"> {p.price} .- CHF </span>  </p> 
                            : 
                            <p className="font-weight-bold text-center"> <span className="font-weight-bold"> {p.price} .- CHF </span>  </p>
                          }         
                        </div>
                      </div>
                    </div>
                  </div>
                
                  </>
                ))}
              </div>
            </div>
    </>
  );
}

export default ProductList;
