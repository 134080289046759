import { useEffect, useState, useReducer, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../config/config";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Collapsible from "react-collapsible";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { Carousel } from "react-responsive-carousel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  extraSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  medium: {
    width: theme.spacing(20),
    height: theme.spacing(15),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    borderRadius: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: theme.spacing(25),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Report() {
  const [run, setRun] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [categorySelect, setCategorySelect] = useState([]);
  const [subCategorySelect, setSubCategorySelect] = useState([]);
  const [products, setProducts] = useState([]);
  const [productlabels, setProductlabels] = useState([]);
  const [labels, setLabels] = useState([]);
  const [unit, setUnit] = useState([]);
  const [productphotos, setProductphotos] = useState([]);

  const [runId, setRunId] = useState(0);
  const [distributorId, setDistributorId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubCategoryId] = useState("");
  const [state, dispatch] = useReducer(reportReducer);
  const classes = useStyles();

  const memoizedCallback = useCallback(() => {
    axios.get(`${config.getUrl}/producthistory`).then((prod) => {
      setProducts(prod.data);
    });
  }, []);

  const memoizedCategory = useCallback(() => {
    axios
      .get(`${config.getUrl}/reportsDistributor/${distributorId}`)
      .then((dis) => {
        setDistributors(dis.data);
      });
  }, []);

  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/run`).then((res) => {
      setRun(res.data);
    });
    axios.get(`${config.getUrl}/productlabel`).then((res) => {
      setProductlabels(res.data);
    });
    axios.get(`${config.getUrl}/productphotos`).then((res) => {
      setProductphotos(res.data);
    });
    axios.get(`${config.getUrl}/unit`).then((res) => {
      setUnit(res.data);
    });
    axios.get(`${config.getUrl}/label`).then((res) => {
      setLabels(res.data);
    });
  }, []);

  function reportReducer(state, action) {
    switch (action.type) {
      case "changeRunReducer": {
        return {
          setRunId: "0",
          setDistributorId: "0",
          setCategoryId: "",
          setDistributors: "[]",
          setSubCategoryId: "0",
          setSubCategorySelect: "[]",
        };
      }
      default:
        break;
    }
    return state;
  }

  // const initialState = {
  //   distributorIdReducer: '0',
  //   categoryIdReducer: '',
  //   distributorsReducer: '[]',
  //   subcategoryIdReducer: '',
  //   subCategorySelectReducer: '[]',
  // }

  function changeRun(e) {
    // dispatch( { type: 'changeRunReducer'} )
    // console.log(state)
    setDistributorId("");
    setCategoryId("");
    setDistributors([]);
    setSubCategoryId("");
    setSubCategorySelect([]);
    if (e.target.value) {
      setRunId(e.target.value);
      axios
        .get(`${config.getUrl}/producthistory/${e.target.value}`)
        .then((prod) => {
          setProducts(prod.data);
        });
      axios
        .get(`${config.getUrl}/reportsDistributor/${e.target.value}`)
        .then((dis) => {
          setDistributors(dis.data);
        });

      axios
        .get(`${config.getUrl}/reportsCategory/${e.target.value}`)
        .then((res) => {
          setCategory(res.data);
        });

      axios
        .get(`${config.getUrl}/reportsSubCat/${e.target.value}`)
        .then((res) => {
          setSubCategory(res.data);
        });
    }
  }
  function changeDistributor(e) {
    setDistributorId(e.target.value);
    setCategorySelect([]);
    setSubCategorySelect([]);
    setCategoryId("");
    setSubCategoryId("");
    axios.get(`${config.getUrl}/reportsCategory/${runId}`).then((res) => {
      if (e.target.value > 0) {
        setCategory(res.data.filter((c) => c.idDistributor === e.target.value));
        axios.get(`${config.getUrl}/reportsSubCat/${runId}`).then((res) => {
          setSubCategory(res.data);
        });
        setCategorySelect([]);
        setCategoryId("");
        setCategorySelect(
          res.data.filter((c) => c.idDistributor === e.target.value)
        );
      } else {
        setCategory(res.data);
        axios.get(`${config.getUrl}/reportsSubCat/${runId}`).then((res) => {
          setSubCategory(res.data);
        });
        // setCategorySelect([
        //   ...new Map(res.data.map((item) => [item['name'], item])).values(),
        // ]);
      }
    });
  }
  function changeCategory(e) {
    setCategoryId(e.target.value);
    setSubCategoryId("");
    if (e.target.value > 0) {
      axios.get(`${config.getUrl}/reportsSubCat/${runId}`).then((res) => {
        setSubCategory(
          res.data.filter((sc) => sc.idCategory === e.target.value)
        );
        setSubCategorySelect([
          ...new Map(
            res.data
              .filter((sc) => sc.idCategory === e.target.value)
              .map((item) => [item["name"], item])
          ).values(),
        ]);
      });
    } else {
      axios.get(`${config.getUrl}/reportsSubCat/${runId}`).then((res) => {
        setSubCategory(res.data);
      });
    }
  }

  function changeSubcategory(e) {
    setSubCategoryId(e.target.value);
  }

  const searchItems = (searchValue) => {
    if (searchValue !== "" && searchValue != null) {
      const filteredData = products.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setProducts(filteredData);
    } else {
      if (distributorId > 0) {
        axios.get(`${config.getUrl}/producthistory/${runId}`).then((prod) => {
          setProducts(prod.data);
        });
        
      } else {
        axios.get(`${config.getUrl}/producthistory/${runId}`).then((prod) => {
          setProducts(prod.data);
        });
      }
    }
  };

  return (
    <div>
      <div className="row w-100 mb-3 d-flex justify-content-end">
        <TextField
          type="text"
          label="Search"
          SelectProps={{
            native: true,
          }}
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={(e) => searchItems(e.target.value)}
        />
      </div>
      <div className="row mb-5">
        <div className="card o-hidden border-0 shadow-sm w-100">
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-12 row">
                <div className="col-lg-3">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="id">Run</InputLabel>
                    <Select
                      // labelId="runId"
                      label="Run"
                      // value={runId}
                      onChange={changeRun}
                      // onChange={e =>
                      //   dispatch({
                      //     type: 'changeRunReducer',
                      //     field: 'changeRun',
                      //     value: e.target.value,
                      //   })
                      // }
                      inputProps={{
                        id: "id",
                      }}
                    >
                      <MenuItem value="">
                        <em>Alles Run auswählen</em>
                      </MenuItem>
                      {run.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-3">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Distributor</InputLabel>
                    {/* <p className="m-0"> Run </p> */}
                    <Select
                      labelId="distributorId"
                      label="Distributor"
                      onChange={changeDistributor}
                      value={distributorId}
                    >
                      <MenuItem value="">
                        <em>Alles Distributor auswählen</em>
                      </MenuItem>
                      {distributors.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-3">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Kategorie</InputLabel>
                    <Select
                      labelId="categoryId"
                      label="Kategorie"
                      onChange={changeCategory}
                      value={categoryId}
                    >
                      <MenuItem value="0">
                        <em>Alles Category auswählen</em>
                      </MenuItem>
                      {categorySelect
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((r) => (
                          <MenuItem key={r.idCategory} value={r.idCategory}>
                            {r.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-3">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Unter-Kategorie</InputLabel>
                    <Select
                      labelId="subcategoryId"
                      label="Unter-Kategorie"
                      value={subcategoryId}
                      onChange={changeSubcategory}
                    >
                      <MenuItem value="">
                        <em>Alles Unter-Kategorie auswählen</em>
                      </MenuItem>
                      {subCategorySelect.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                          {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* <div className="col-lg-12 row">
              <TextField
                  type="text"
                  placeholder="Search"
                  // value={searchTerm}
                  // onChange={handleChange}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {distributors
        .filter((dist) =>
          distributorId > 0
            ? dist.id === distributorId
            : dist.id !== distributorId
        )
        .map((dist) => (
          <div>
            <div className="w-100 d-flex justify-content-between">
              <img alt="" src={dist.logoPath} style={{ height: "50px" }} />
              <p className="mb-0 pt-3 mr-2">
                {products.filter((pk) => pk.idDistributor === dist.id).length}{" "}
                Artikel
              </p>
            </div>
            <div className={"card o-hidden border-0 shadow-sm w-100 mb-5"}>
              {category
                .filter(
                  (cat) =>
                    cat.idDistributor === dist.id &&
                    (categoryId > 0
                      ? cat.idCategory === categoryId
                      : cat.idCategory !== categoryId)
                )
                .map((cat) => (
                  <Collapsible
                    classParentString={"category"}
                    open={true}
                    trigger={cat.name}
                  >
                    {subCategory
                      .filter(
                        (sc) =>
                          sc.idDistributor === dist.id &&
                          sc.idCategory === cat.idCategory
                        //  &&(categoryId > 0
                        //   ? sc.id === subcategoryId
                        //   : sc.id !== subcategoryId)
                      )
                      .map((sub) => (
                        <Collapsible
                          open={true}
                          classParentString={"subcategory"}
                          trigger={
                            sub.name +
                            " - " +
                            products.filter(
                              (pk) =>
                                pk.idDistributor === dist.id &&
                                pk.idSubCategory == sub.id
                            ).length +
                            " Arikel"
                          }
                        >
                          {products
                            .filter(
                              (pk) =>
                                pk.idSubCategory === sub.id &&
                                pk.idRun === runId &&
                                pk.idDistributor === cat.idDistributor
                            )
                            .map((p) => (
                              <>
                                <div className="row">
                                  <div className="col-lg-2">
                                    <img
                                      alt=""
                                      className={classes.medium}
                                      src={productphotos
                                        .filter(
                                          (pp) =>
                                            pp.idProduct === p.idProduct &&
                                            pp.isProfilePic === true
                                        )
                                        .map((f) => f.logoSource)}
                                    />
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mt-3">
                                      <span className="font-weight-bold">
                                        {p.name}
                                      </span>
                                      <br />
                                      <span className="small">
                                        {p.description}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-lg-2 mt-2">
                                    <Carousel
                                      autoPlay={true}
                                      emulateTouch={true}
                                      infiniteLoop={true}
                                      stopOnHover={false}
                                      // centerMode={true}
                                      showStatus={false}
                                      showIndicators={false}
                                      showThumbs={true}
                                    >
                                      {productlabels
                                        .filter(
                                          (pL) => pL.idProduct === p.idProduct
                                        )
                                        .map((pl) =>
                                          labels
                                            .filter((f) => f.id === pl.idLabel)
                                            .map((l) => (
                                              <div>
                                                <img
                                                  src={l.logoSource}
                                                  //  className={classes.medium}
                                                ></img>
                                              </div>
                                              // <img
                                              // className={classes.small}
                                              // src={l.logoSource}
                                              // >
                                              // </img>
                                            ))
                                        )}
                                    </Carousel>
                                  </div>
                                  <div className="col-lg-2 mt-2">
                                    {p.quantity}
                                    {unit
                                      .filter((u) => u.id === p.idUnit)
                                      .map((up) => up.name)}
                                    <br />
                                    {p.price.toFixed(2)}
                                    <br />
                                    <span className="small">
                                      {p.defaultQuantity}
                                      {unit
                                        .filter((u) => u.id === p.idUnit)
                                        .map((up) => up.name)}{" "}
                                      =
                                      {(
                                        (p.defaultQuantity * p.price) /
                                        p.quantity
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>
                              </>
                            ))}
                        </Collapsible>
                      ))}
                  </Collapsible>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Report;
