import { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import config from '../../config/config';


const Runs = (props) => {
  const [run, setRun] = useState([]);
  const [distributor, setDistributors] = useState([]);
  const [distributorRun, setDistributorRun] = useState([]);
  const showRun = () => {setRun('');};

  let history = useHistory();

  const {
    register,
    reset,
    handleSubmit,
  } = useForm();

  function onSubmit(data) {

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
      },
      body:{
          'body': JSON.stringify({data})
      }
    };
    axios
    .post(`${config.postUrl}/run`, data)
    .then((res) => {
      history.push('.');
    })
    .catch((error) => {});
}

useEffect(() => {
  var auth = auth;
  axios.get(`${config.getUrl}/run`).then((res) => {
    setRun(res.data);
  });
  axios.get(`${config.getUrl}/distributors`).then((res) => {
    setDistributors(res.data);
  });
  axios.get(`${config.getUrl}/distributorRun`).then((res) => {
    setDistributorRun(res.data);
  });
}, []);

function handleChange(e) {
  if (e.target.checked) {
    const str = e.target.value;
    const strCopy = str.split(',');

    //e.preventDefault();
    const data = {
      idDistributor: strCopy[1],
      idRun: strCopy[0],
    };
    axios
      .post(`${config.postUrl}/distributorRun`, data)
      .then((res) => {
        axios.get(`${config.getUrl}/distributorRun`).then((res) => {
          setDistributorRun(res.data);
        });
      })
      .catch((error) => {});
  }

  else{
    const str = e.target.value;
    const strCopy = str.split(',');
    const data = {
      idDistributor: strCopy[1],
      idRun: strCopy[0],
    };
    axios
     .delete(`${config.deleteUrl}/distributorRun`, { params: { idRun: strCopy[0], idDistributor:strCopy[1] } })
      .then((res) => {
        axios.get(`${config.getUrl}/distributorRun`).then((res) => {
          setDistributorRun(res.data);
        });
      })
      .catch((error) => {}); 
  }
}




    return (
      <>
      <div className={"row card card o-hidden border-0 shadow-sm mb-5 " +run}>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
              <div className="form-group">
                <label> Name </label> 
                <input type="text"  ref={register({ required: true })}  name="Name"/> 
              </div>
              <div className="form-group">
                <label> Datum </label> 
                <input type="date"  ref={register({ required: true })}  name="Date"/> 
              </div>
              <div className="form-group hidden">
                <label> Status </label> 
                <input type="radio"  
                ref={register()} 
                value="1"
                checked="checked"
                name="Status"/> 
              </div>
              <div className="form-group mt-5">
                  <button className="btn btn-light" type="submit"> Speichern </button> 
              </div>
          </form>
        </div>
      </div>

      <div className={"row card card o-hidden border-0 shadow-sm my-5"}> 
      <table className="table table-striped table-bordered mb-0">
              <thead className="font-weight-bold">
                <tr>
                  <td>Run</td>
                  <td>Datum</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody> 
              {run.map((r) => (
                  
                        <tr>
                          <td>{r.name} </td>
                          <td>{ r.date.substring(0, 10) }</td>
                          <td>{r.status}</td>
                        </tr>
                    
                ))}
             </tbody>
          </table>
      </div>

      <div className="row card card 0-hidden border-0 shadow-sm m">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  {distributor.map((d) => (
                        <th key={d.id}>
                          {d.name}
                        </th>
                      ))}
                </tr>
              </thead>
              <tbody>
              {run.map((r) => (
                    <tr  key={r.id}>
                      <td key={r.id} >{r.name} </td>
                      {distributor.map((d) => (
                        <td className="column100 column1" key={d.id}>
                          {/* {userPerm.filter((u) => u.idCustomer === 1)} */}
                          <input
                            type="checkbox"
                            checked={
                              distributorRun.filter(
                                (up) =>
                                  up.idRun === r.id &&
                                  up.idDistributor === d.id
                              ).length > 0
                                ? 'checked'
                                : ''
                            }
                            value={r.id + ',' + d.id}
                            onChange={handleChange}

                            //onChange={() => setChecked(!checked)}
                          />
                        </td>
                      ))}
                    </tr>
              ))}
              </tbody>
            </table>             
      </div>
     </>
    );
  };
  
  export default Runs;