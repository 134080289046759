import { useParams } from 'react-router-dom';
import { useEffect, useState, useForm } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from '../../config/config';
import axios from 'axios';
import {
  BsTable
} from 'react-icons/fa';

function Products() {
    const [index, setIndex] = useState(0);
    const { id } = useParams();
    const [product, setProduct] = useState([]);
    const [productPhotos, setProductPhotos] = useState([]);
    const [brand, setBrand] = useState([]);
    const [unit, setUnit] = useState([]);

    useEffect(() => {
      var auth = auth;
      axios.get(`${config.getUrl}/product/${id}`).then((product) => {
        setProduct(product.data.product);
      });
      axios.get(`${config.getUrl}/productphotos/${id}`).then((productPhotos) => {
        setProductPhotos(productPhotos.data);
      });
    }, []);
    useEffect(() => {
      var auth = auth;
      axios.get(`${config.getUrl}/brand/${product.idBrand}`).then((brand) => {
        setBrand(brand.data);
      });
      axios.get(`${config.getUrl}/unit/${product.idUnit}`).then((unit) => {
        setUnit(unit.data);
        console.log(unit.data);
      });
    }, [product.id]);

  return (
    <div className="container">
    
    <div className="row mt-5">
      <div className="col-lg-6">
      <Carousel
      autoPlay="true"
      emulateTouch="true"
      infiniteLoop="true"
      stopOnHover="true"	>
          {productPhotos.map((pp) => (
                <div>
                <img src={pp.logoSource}></img>
            </div>
            ))}
               
        </Carousel>
      </div>
      <div className="col-lg-6 test1">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Produktbezeichnung</label>
            <label className="catBody"> {product.name}</label>
          </div>
        </div>
        <div className="row">    
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Weitere Produktangaben</label>
            <label className="catBody"> {product.description}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Marke</label>
            <label className="catBody"> {brand.name}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Hersteller</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> EAN-Code</label>
            <label className="catBody"> {product.eanCode}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Gewicht, Einheit</label>
            <label className="catBody"> {product.quantity} {unit.name} </label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Regulärpreis</label>
            <label className="catBody"> {product.price}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Aktionsmenge / Preis</label>
            <label className="catBody"> {product.aprice}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Label vegetarisch/vegan</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Bio-Label </label>         
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Allergikerlabel Gluten</label>
            <label className="catBody"> {product.gluten ? "Gluten" : "Gluten Free"}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Allergikerlabel Laktose</label>       
            <label className="catBody"> {product.lactose ? "Lactose" : "Lactose Free"} </label>
          </div>
        </div>
        <div className="row">    
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Produktkategorie</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Aritkeleinkauf/-Fotos am</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Daten zuletzt aktualisiert</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12 p-0 ml-5">
            <label className="catHeader"> Verkaufsstelle</label>
            <label className="catBody"> Hello World2</label>
          </div>
        </div>
      </div>
    </div>
      
    </div>
  );
}



export default Products;
