import Form from 'react-bootstrap/Form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useRef, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    borderRadius: theme.spacing(4),
  },
}));
const AddBrand = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const isAddMode = !id;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    errors,
    formState,
  } = useForm();
  const fileInput = createRef();
  function onSubmit(data) {
    return isAddMode ? createBrand(data) : updateBrand(id, data);
  }
  function createBrand(data) {
    // const fd = new FormData();
    // for (var key in data) {
    //   fd.append(key, data[key]);
    // }
    // fd.append('image', file, fileName);

    axios
      .post(`${config.postUrl}/brand`, data)
      .then((res) => {
        history.push('.');
      })
      .catch((error) => {});
    //console.log(data);
  }

  function updateBrand(id, data) {
    axios
      .put(`${config.putUrl}/brand/${id}`, data)
      .then((res) => {
        history.push('..');
      })
      .catch((error) => {});
  }

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [logo, setLogo] = useState();

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/brand/${id}`).then((brand) => {
        setLogo(brand.data.logoSource);
        const fields = Object.keys(brand.data);
        fields.map((field) => setValue(field, brand.data[field]));
      });
    }
  }, []);

  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-5">
            {isAddMode ? 'Add Brand' : 'Edit Brand'}
          </h3>
            <div className="col-lg-12 d-flex justify-content-center">
            <div className="col-lg-6">
              <label>Brand</label>
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
                className="form-control"
                placeholder="Brand Name"
              ></input>
              {errors.name && <span className="errorMessage">Pflichtfeld</span>}
            </div>
            </div>
            <div className="col-lg-12 mt-2 d-flex justify-content-center">
            <div className="col-lg-6">
              {/* <label>Logo</label> */}
              {/* <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    id="brand-logo"
                    type="file"
                    className="custom-file-input "
                    name="logoFile"
                    ref={fileInput}
                    onChange={saveFile}
                  />
                  <label className="custom-file-label" for="brand-logo">
                    Choose Photo
                  </label>
                </div>
              </div> */}
            </div>


          </div>
            <div className="col-lg-12 form-group mt-5 d-flex justify-content-center">
              <input type="submit" className="nav-link btn lpbgColor"></input>
            </div>
        </form>
      </main>
    </>
  );
};

export default AddBrand;
