import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { mainListItems, secondaryListItems, thirdListItems } from './listItems';
import Button from '@material-ui/core/Button';

import { HashRouter, Route, Switch, Link } from 'react-router-dom';
import SignUp from '../Auth/SignUp';
import RegisterForm from '../Auth/RegisterForm';
import Login from '../Auth/Login';
import UserPermisions from '../UserPermisions/UserPermisions';
import UserList from '../Auth/UserList';
import Products from '../Products/Products';
import AddProducts from '../Products/AddProducts';
import AddProductsPhotos from '../Products/AddProductPhotos';
import ProductList from '../Products/ProductList';

import AddCustomers from '../Customers/AddCustomers';
import CustomersList from '../Customers/CustomersList';
import Distributorlist from '../Distributor/DistributorList';
import AddDistributor from '../Distributor/AddDistributor';
import BrandList from '../Brands/BrandList';
import AddBrand from '../Brands/AddBrand';
import UnitList from '../Units/UnitList';
import AddUnits from '../Units/AddUnits';
import LabelList from '../Label/LabelList';
import AddLabel from '../Label/AddLabel';
import LactoseList from '../Lactose/LactoseList';
import AddLactose from '../Lactose/AddLactose';
import ManufacturerList from '../Manufacturer/ManufacturerList';
import AddManufacturer from '../Manufacturer/AddManufacturer';
import MainCategoryList from '../MainCategory/MainCategoryList';
import AddMainCategory from '../MainCategory/AddMainCategory';
import OriginList from '../Origin/OriginList';
import AddOrigin from '../Origin/AddOrigin';
import Reports from '../Reports/Reports';
import Runs from '../Runs/Runs';
import useToken from '../../useToken.js';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Lp Marktforschung
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const { token, setToken } = useToken();
  const { removeToken } = useToken();
  const { fullname } = useToken();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const Page404 = () => <h1>Four:oh:four</h1>;
  const handleSubmit = async (e) => {
    e.preventDefault();
    removeToken(token);
    window.location.href = '/';
  };
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          {auth && (
            <Button color="inherit" onClick={handleSubmit}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
        <Divider />
        <List>{thirdListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route exact path="/" component={UserList} />
            <Route path="/userList/signup" component={SignUp} />
            <Route path="/userList/register" component={RegisterForm} />
            <Route path="/userPermision" component={UserPermisions} />
            <Route path="/userList" component={UserList} />
            <Route path="/productslist/products/:id" component={Products} />
            <Route path="/productslist/addproduct" component={AddProducts} />
            <Route
              path="/productslist/addproductphotos/:id"
              component={AddProductsPhotos}
            />
            <Route
              path="/productslist/editproduct/:id"
              component={AddProducts}
            />
            <Route path="/productslist" component={ProductList} />
            <Route path="/runs" component={Runs} />
            <Route path="/customerslist/addcustomer" component={AddCustomers} />
            <Route
              path="/customerslist/editcustomer/:id"
              component={AddCustomers}
            />
            <Route path="/customerslist" component={CustomersList} />
            <Route path="/dashboard" component={Dashboard} />

            <Route
              path="/distributor/adddistributor"
              component={AddDistributor}
            />
            <Route
              path="/distributor/editdistributor/:id"
              component={AddDistributor}
            />
            <Route path="/distributor" component={Distributorlist} />

            <Route path="/brands/addbrand" component={AddBrand} />
            <Route path="/brands/editbrand/:id" component={AddBrand} />
            <Route path="/brands" component={BrandList} />

            <Route path="/units/addunits" component={AddUnits} />
            <Route path="/units/editunits/:id" component={AddUnits} />
            <Route path="/units" component={UnitList} />

            <Route path="/label/addlabel" component={AddLabel} />
            <Route path="/label/editlabel/:id" component={AddLabel} />
            <Route path="/label" component={LabelList} />

            <Route path="/manufacturer/addmf" component={AddManufacturer} />
            <Route
              path="/manufacturer/editmf/:id"
              component={AddManufacturer}
            />
            <Route path="/manufacturer" component={ManufacturerList} />

            <Route
              path="/categories/addcategories"
              component={AddMainCategory}
            />
            <Route
              path="/categories/editcategories/:id"
              component={AddMainCategory}
            />
            <Route path="/categories" component={MainCategoryList} />

            <Route path="/origin/addorigin" component={AddOrigin} />
            <Route path="/origin" component={OriginList} />
            <Route path="/Reports" component={Reports} />
            {/* <Route path="/Reporttest" component={ReportTest} /> */}
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
