import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPen, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';

function ManufacturerList() {
  const [error, setError] = useState(null);
  const [manufacturer, setManufacturer] = useState([]);
  const { token, auth } = useToken();

  function deleteUser(id) {
    axios
      .delete(`${config.deleteUrl}/manufacturer/${id}`, { params: { id: id } })
      .then((response) => {
        setManufacturer((manufacturer) => manufacturer.filter((x) => x.id !== id));
      });
  }
  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/manufacturer`).then((res) => {
      setManufacturer(res.data);
    });
  }, []);

  return (
    <div className="col-lg-12 mt-5">
      <Link className="btn lpbgColor mb-3" to="/manufacturer/addmf">
        Add Manufacturer
      </Link>
      <div className="card o-hidden border-0 shadow-sm "> 
      <table className="table table-striped mb-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {manufacturer.map((i) => (
            <tr key={i.id}>
              <td>{i.name}</td>
              <td>
                <Link
                  className="text-primary mr-2"
                  to={`/manufacturer/editmf/${i.id}`}
                >
                  <FaPen />
                </Link>
                <a onClick={() => deleteUser(i.id)} className="text-danger">
                  <FaTrash />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default ManufacturerList;
