import { React, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ControlLabel from '@material-ui/core/FormControlLabel';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function SignUp() {
  const classes = useStyles();
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [role, setRole] = useState();
  const [customer, setCustomer] = useState();
  const [error, setError] = useState();
  const { register, reset, handleSubmit, watch, errors, formState } = useForm();

  const changeCustomer = (value) => {
    var auth = auth;
    axios.get(`${config.getUrl}/roles/${value}`).then((res) => {
      setRoles(res.data);
    });
    setRole('');
  };

  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/customers`).then((res) => {
      setCustomers(res.data);
    });
  }, []);
  const onSubmit = async (e) => {
    //e.preventDefault();
    const data = {
      username: username,
      password: password,
      firstname: firstName,
      lastName: lastName,
      idRole: role,
      idCustomer: customer,
    };
    axios
      .post(`${config.authUrl}/register`, data)
      .then((res) => {
        history.push('.');
        console.log(res);
        console.log(res.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          setError('Benutzer existiert bereits');
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className="lpbgColor mb-5"></Avatar>
        {/* <div
          class="alert alert-danger"
          role="alert"
          hidden={error ? '' : 'hidden'}
        >
          {error}
        </div> */}
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Customers</FormLabel>
                {customers.map((item) => (
                  <div className="form-check">
                    <input
                      onChange={(e) => setCustomer(e.target.value)}
                      name="customers"
                      class="form-check-input"
                      type="radio"
                      id="customers"
                      value={item.id}
                      onClick={() => changeCustomer(item.id)}
                      inputRef={register({ required: true })}
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      {item.name}
                    </label>
                  </div>
                ))}
                {errors.customers && (
                  <span className="errorMessage">Pflichtfeld</span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="roles">
                <FormLabel component="legend">Roles</FormLabel>
                {roles.map((item) => (
                  <div className="form-check">
                    <input
                      onChange={(e) => setRole(e.target.value)}
                      name="roles"
                      class="form-check-input"
                      type="radio"
                      id="roles"
                      value={item.id}
                      inputRef={register({ required: true })}
                    />
                    <label class="form-check-label" for="exampleRadios1">
                      {item.name}
                    </label>
                  </div>
                ))}
                {errors.roles && (
                  <span className="errorMessage">Pflichtfeld</span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                helperText={errors.firstName && 'Pflichtfeld'}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                fullWidth
                id="firstName"
                label="First Name *"
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
                inputRef={register({ required: true })}
                error={!!errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                label="Last Name *"
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastName(e.target.value)}
                inputRef={register({ required: true })}
                error={!!errors.lastName}
                helperText={errors.lastName && 'Pflichtfeld'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setUserName(e.target.value)}
                inputRef={register({
                  required: 'Pflichtfeld',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Dies ist keine valide E-Mail Adresse',
                  },
                })}
                error={!!errors.email}
                helperText={
                  (errors.email && errors.email.message) || (error && error)
                }
              />
              {error && <span className="errorMessage">{error}</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                inputRef={register({
                  required: 'Pflichtfeld',
                  minLength: {
                    value: 6,
                    message: 'Anforderungen Passwort: mind. 6 Zeichen',
                  },
                })}
                error={!!errors.password}
                helperText={errors.password && errors.password.message}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-5 lpbgColor"
          >
            Sign Up
          </Button>
        </form>
      </div>
    </Container>
  );
}
