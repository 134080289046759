import react from 'react';
// import './lib/main.css';
// import './lib/util.css';
import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';

function UserPermisions() {
  const [customerId, setCustomerId] = useState(0);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [mainCat, setMainCat] = useState([]);
  const [userPerm, setUserPerm] = useState([]);
  const { token, auth } = useToken();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/customers`).then((res) => {
      setCustomer(res.data);
      console.log(res.data);
    });

    axios.get(`${config.getUrl}/distributors`).then((res) => {
      setDistributor(res.data);
    });

    axios.get(`${config.getUrl}/userPerm`).then((res) => {
      setUserPerm(res.data);
    });

    axios.get(`${config.getUrl}/mainCategories`).then((res) => {
      setMainCat(res.data);
    });
  }, [customerId]);

  function handleChange(e) {
    const str = e.target.value;
    const strCopy = str.split('');
    console.log(strCopy[0]);
    //e.preventDefault();
    const data = {
      id: 0,
      idCustomer: customerId,
      idDistributor: strCopy[1],
      idSubCategory: strCopy[0],
    };
    if (e.target.checked) {
      axios
        .post(`${config.postUrl}/userPermisions`, data)
        .then((res) => {
          axios.get(`${config.getUrl}/userPerm`).then((res) => {
            setUserPerm(res.data);
          });
        })
        .catch((error) => {});
    } else {
      // axios
      //   .delete(`${config.deleteUrl}/userPermisions`, { data: { data } })
      //   .then((response) => {
      //     axios.get(`${config.getUrl}/userPerm`).then((res) => {
      //       setUserPerm(res.data);
      //     });
      //   });

      axios
        .delete(`${config.deleteUrl}/userPermisions`, {
          params: {
            idCustomer: customerId,
            idDistributor: strCopy[1],
            idSubCategory: strCopy[0],
          },
        })
        .then((response) => {
          axios.get(`${config.getUrl}/userPerm`).then((res) => {
            setUserPerm(res.data);
          });
        });
    }
  }

  return (
    <>
      <div className="form-group mt-5 col-lg-4">
        <label>Customer</label>
        <select
          className="form-control"
          onChange={(e) => setCustomerId(e.target.value)}
        >
          <option value="0"></option>
          {customer.map((c) => (
            <option key={c.id} value={c.id}>
              {c.name}
            </option>
          ))}
        </select>
        <span className="text-danger"></span>
      </div>
      <div className="mt-5">
        <div className="container">
          <div className="table100 ver1 m-b-110">
            <table data-vertable="ver2">
              <thead>
                <tr className="row100 head">
                  <th className="column100 column1" data-column="column1"></th>
                  {distributor.map((d) => (
                    <th className="column100 column1" key={d.id}>
                      {d.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {renderTableData()} */}
                {mainCat.map((mc) => (
                  <tr className="row100" key={mc.subCategory.id}>
                    <td
                      className="column100 column1"
                      data-column="column1"
                      key={mc.subCategory.id}
                    >
                      {mc.subCategory.name}
                    </td>
                    {distributor.map((d) => (
                      <td className="column100 column1" key={d.id}>
                        {/* {userPerm.filter((u) => u.idCustomer === 1)} */}
                        <input
                          type="checkbox"
                          checked={
                            userPerm.filter(
                              (up) =>
                                up.idCustomer === parseInt(customerId, 10) &&
                                up.idDistributor === d.id &&
                                up.idSubCategory === mc.subCategory.id
                            ).length > 0
                              ? 'checked'
                              : ''
                          }
                          value={mc.subCategory.id + '' + d.id}
                          onChange={handleChange}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPermisions;
