import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useToken from '../../useToken';

function RegisterForm() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [roles, setRoles] = useState([]);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [role, setRole] = useState();
  const [customer, setCustomer] = useState();
  const url = 'https://localhost:44354/api/get';
  const { headers } = useToken();
  useEffect(() => {
    fetch(`${url}/customers`, { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
          console.log(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  function handleChange(value) {
    fetch(`${url}/roles/${value}`, { headers })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setRoles(result);
          console.log(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
      idRole: role,
      idCustomer: customer,
    };
    fetch(`${url}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((data) => data.json());
    //window.location.href = '/';
  };
  return (
    <div className="col-lg-6 mt-5">
      <form onSubmit={handleSubmit}>
        <label className="form-label">Customers</label>
        {items.map((item) => (
          <div className="form-check">
            <input
              onChange={(e) => setCustomer(e.target.value)}
              name="customers"
              class="form-check-input"
              type="radio"
              name="customerRadio"
              id="customerRadio"
              value={item.id}
              onClick={() => handleChange(item.id)}
            />
            <label class="form-check-label" for="exampleRadios1">
              {item.name}
            </label>
          </div>
        ))}

        <label className="form-label">Roles</label>
        {roles.map((item) => (
          <div className="form-check">
            <input
              onChange={(e) => setRole(e.target.value)}
              name="roles"
              class="form-check-input"
              type="radio"
              name="roleRadio"
              id="roleRadio"
              value={item.id}
            />
            <label class="form-check-label" for="exampleRadios1">
              {item.name}
            </label>
          </div>
        ))}
        <div className="form-group" controlId="formBasicEmail">
          <label className="form-label">Email address</label>
          <input
            onChange={(e) => setUserName(e.target.value)}
            name="email"
            className="form-control"
            type="email"
            placeholder="Enter email"
          />
        </div>

        <div className="form-group" controlId="formBasicPassword">
          <label className="form-label">Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            className="form-control"
            type="password"
            placeholder="Password"
          />
        </div>
        <div className="form-group" controlId="formBasicPassword">
          <label className="form-label">Confirm Password</label>
          <input
            className="form-control"
            type="password"
            placeholder="Confirm Password"
          />
        </div>
        <div className="form-group" controlId="formBasicCheckbox">
          <input className="form-check" type="checkbox" label="Check me out" />
        </div>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default RegisterForm;
