import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPen, FaTrash } from 'react-icons/fa';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';

function GlutenList() {
  const [label, setLabel] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [distributorLabel, setDistributorLabel] = useState([]);

  function handleChange(e) {
    if (e.target.checked) {
      const str = e.target.value;
      const strCopy = str.split(',');
      console.log(strCopy[0]);

      //e.preventDefault();
      const data = {
        idDistributor: strCopy[1],
        idLabel: strCopy[0],
      };
      axios
        .post(`${config.postUrl}/distributorLabel`, data)
        .then((res) => {
          var auth = auth;
          axios.get(`${config.getUrl}/distributorlabel`).then((res) => {
            setDistributorLabel(res.data);
            console.log(res.data);
          });
        })
        .catch((error) => {});
    }
  }

  function deleteLabel(id) {
    axios
      .delete(`${config.deleteUrl}/label/${id}`, { params: { id: id } })
      .then((response) => {
        setLabel((label) => label.filter((x) => x.id !== id));
      });
  }
  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/label`).then((res) => {
      setLabel(res.data);
    });
  }, []);

  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/label`).then((res) => {
      setLabel(res.data);
    });

    var auth = auth;
    axios.get(`${config.getUrl}/distributors`).then((res) => {
      setDistributor(res.data);
    });

    var auth = auth;
    axios.get(`${config.getUrl}/distributorLabel`).then((res) => {
      setDistributorLabel(res.data);
    });
  }, []);

  return (
    <div className="col-lg-12 mt-5">
      <Link className="btn lpbgColor mb-3" to="/label/addlabel">
        Add Label
      </Link>
      <div className="card o-hidden border-0 shadow-sm mb-5"> 
      <table className="table table-striped mb-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {label.map((i) => (
            <tr key={i.id}>
              <td>{i.name}</td>
              <td>
                <Link
                  className="text-primary mr-2"
                  to={`/label/editlabel/${i.id}`}
                >
                  <FaPen />
                </Link>
                <a onClick={() => deleteLabel(i.id)} className="text-danger">
                  <FaTrash />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="card o-hidden border-0 shadow-sm ">   
      <div className="col-lg-12 row">
        <div className="mt-5">
          <div className="w-100">
            <div className="table100 ver1 m-b-110">
              <table data-vertable="ver2">
                <thead>
                  <tr className="row100 head  w-100">
                    <th
                      className="column100 column1"
                      data-column="column1"
                    ></th>
                    {distributor.map((d) => (
                      <th className="column100 column1" key={d.id}>
                        {d.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* {renderTableData()} */}
                  {label.map((c) => (
                    <tr className="row100" key={c.id}>
                      <td
                        className="column100 column1"
                        data-column="column1"
                        key={c.id}
                      >
                        {c.name}
                      </td>
                      {distributor.map((d) => (
                        <td className="column100 column1" key={d.id}>
                          {/* {userPerm.filter((u) => u.idCustomer === 1)} */}
                          <input
                            type="checkbox"
                            checked={
                              distributorLabel.filter(
                                (up) =>
                                  up.idLabel === c.id &&
                                  up.idDistributor === d.id
                              ).length > 0
                                ? 'checked'
                                : ''
                            }
                            value={c.id + ',' + d.id}
                            onChange={handleChange}

                            //onChange={() => setChecked(!checked)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default GlutenList;
