import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  FaPen,
  FaEdit,
  FaTrash,
  FaPlus,
  FaLock,
  FaLockOpen,
} from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';

function UserList() {
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const { token, auth } = useToken();

  function getUsers() {
    var auth = auth;
    axios.get(`${config.getUrl}/users`).then((res) => {
      setUser(res.data);
      console.log(res.data);
    });
  }
  function statusUser(username) {
    axios
      .put(`${config.putUrl}/userstatus/${username}`, {
        params: { username: username },
      })
      .then((response) => {
        getUsers();
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="col-lg-12 mt-5 box-sh p-3">
      <Link className="btn lpbgColor" to="/userList/signup">
        Add User
      </Link>
      <table className="table mt-3">
        <thead>
          <tr>
            <th scope="col">Firstname</th>
            <th scope="col">Lastname</th>
            <th scope="col">Username</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {user.map((i) => (
            <tr key={i.id}>
              <td>{i.firstName}</td>
              <td>{i.lastName}</td>
              <td>{i.username}</td>
              <td>
                <Link
                  className="text-primary mr-2"
                  to={`/productslist/editproduct/${i.id}`}
                >
                  <FaPen />
                </Link>
                <a
                  onClick={() => statusUser(i.username)}
                  className={
                    i.status === false ? 'text-success' : 'text-danger'
                  }
                >
                  {i.status === false ? <FaLockOpen /> : <FaLock />}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserList;
