import './App.css';
import { useEffect, useContext, createContext, useState } from 'react';
import { HashRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
import SignIn from './components/Auth/SignIn';
import useToken from './useToken.js';
import Dashboard from './components/Dashboard/Dashboard';

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useToken().auth;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  //console.log(useToken().auth);
  // var user = useToken().auth;
  // useEffect(() => {
  //   //check if user is logedin

  //   if (!user) {
  //     window.location = '#/login';
  //   }
  //   // } else {
  //   //   this.globals.getUserSettings(user);
  //   // }
  // }, []);

  return (
    <div>
      <ProvideAuth>
        <HashRouter>
          <Switch>
            <Route path="/login" exact component={SignIn} />
            <PrivateRoute path="/">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </HashRouter>
      </ProvideAuth>
    </div>
  );
}

export default App;
