import { useEffect, useState, useForm } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPen, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useToken from '../../useToken';
import config from '../../config/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

function CategoriesList() {
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);

  function deleteCategory(id) {
    axios
      .delete(`${config.deleteUrl}/mainCategories/${id}`, {
        params: { id: id },
      })
      .then((response) => {
        setCategories((product) => product.filter((x) => x.id !== id));
      });
  }

  useEffect(() => {
    var auth = auth;
    axios.get(`${config.getUrl}/categoriesList`).then((res) => {
      setCategories(res.data);
    });
  }, []);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
  const classes = useStyles();

  

  return (
    <div className="col-lg-12 mt-5">
      {
      /* 
      to-do kur ti vjen koha
      
      <Link className="btn lpbgColor mb-3 mr-5" to="/categories/addcategories">
        Add Haupt-Kategorie	
      </Link>
      <Link className="btn lpbgColor mb-3 mr-5" to="/categories/addcategories">
        Add Kategorie
      </Link>
      <Link className="btn lpbgColor mb-3" to="/categories/addcategories">
        Add Unter-Kategorie	
      </Link> */}
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell className={"text-uppercase font-weight-bold"}>Haupt-Kategorie</TableCell>
            <TableCell className={"text-uppercase font-weight-bold"}>Kategorie</TableCell>
            <TableCell className={"text-uppercase font-weight-bold"}>Unter-Kategorie</TableCell>
            {/* <th scope="col"></th> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((i) => (
            <TableRow key={i.id}>
              <TableCell>{i.mainCategory}</TableCell>
              <TableCell>{i.category}</TableCell>
              <TableCell>{i.subCategory}</TableCell>
              {/* <td>
                <Link
                  className="text-primary mr-2"
                  to={`/categories/editcategories/${i.id}`}
                >
                  <FaPen />
                </Link>
                <a onClick={() => deleteCategory(i.id)} className="text-danger">
                  <FaTrash />
                </a>
              </td> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
    </div>
  );
}

export default CategoriesList;
