import React, { useState, StrictMode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import config from '../../config/config';
import axios from 'axios';
import useToken from '../../useToken.js';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  let history = useHistory();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [validationUsr, setValidationUsr] = useState(false);
  const [validationPwd, setValidationPwd] = useState(false);
  const [open, setOpen] = useState(false);
  const { setToken } = useToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const credentials = {
      username,
      password,
    };
    //console.log(credentials);
    if (username && password) {
      axios
        .post(`${config.authUrl}/login`, credentials)
        .then((res) => {
          setToken(res.data);
          window.location.href = '/';
        })
        .catch((error) => {
          setOpen(true);
        });
    } else {
      username || setValidationUsr(true);
      password || setValidationPwd(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUsername = async (e) => {
    setUserName(e.target.value);
    if (!e.target.value) setValidationUsr(true);
    else setValidationUsr(false);
  };
  const handlePassword = async (e) => {
    setPassword(e.target.value);
    if (!e.target.value) setValidationPwd(true);
    else setValidationPwd(false);
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Benutzername oder Passwort ist falsch!
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <Avatar className="lpbgColor">{/* <LockOutlinedIcon /> */}</Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            type="email"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleUsername}
            error={validationUsr}
            helperText={validationUsr && 'Pflichtfeld'}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePassword}
            error={validationPwd}
            helperText={validationPwd && 'Pflichtfeld'}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="mt-5 lpbgColor"
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
