import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../config/config';
import axios from 'axios';
import { useForm } from 'react-hook-form';

const AddManufacturer = (props) => {
  const { id } = useParams();
  let history = useHistory();
  const isAddMode = !id;
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    errors,
  } = useForm();

  
  const [manufacturer, setManufacturer] = useState([]);


  function onSubmit(data) {
    return isAddMode ? createManufacturer(data) : updateManufacturer(id, data);
  }
  function createManufacturer(data) {
    axios
      .post(`${config.postUrl}/manufacturer`, data)
      .then((res) => {
        history.push('.');
      })
      .catch((error) => {});
  }
  function updateManufacturer(id, data) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };
    fetch(`${config.putUrl}/manufacturer/${id}`, requestOptions).then((res) => {
      history.push('..');
    });
  }

  useEffect(() => {
    if (!isAddMode) {
      var auth = auth;
      axios.get(`${config.getUrl}/manufacturer/${id}`).then((manufacturer) => {
        const fields = Object.keys(manufacturer.data);
        fields.map((field) => setValue(field, manufacturer.data[field]));
      });
    }
  }, []);
  return (
    <>
      <main className="box-sh p-3">
        <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
          {isAddMode ? '' : <input name="id" type="hidden" ref={register()} />}
          <h3 className="text-center mb-3">
            {isAddMode ? 'Add Manufacturer' : 'Edit Manufacturer'}
          </h3>

          <div className="col-lg-12 d-flex justify-content-center">
          <div className="col-lg-6">
              <label>Manufacturer</label>
              <input
                type="text"
                name="name"
                ref={register({ required: true })}
                className="form-control"
                placeholder="Manufacturer Name"
              ></input>
              {errors.name && <span className="errorMessage">Pflichtfeld</span>}
            </div>
            </div>
            {/* <div className="col-lg-6">
              <label>Manufacturer</label>
              <input type="text" 
              className="form-control"
              name="name"
              ref={register({ required: true })} 
              placeholder="Manufacturer Name"
              ></input>
              {errors.name && (
                <span className="errorMessage">Pflichtfeld</span>
              )}
            </div> */}
            {/* <div className="col-lg-12 form-group">
              <label>Status</label>
              <input type="checkbox" className="checkbox"></input>
              {errors.distributor && (
                <span className="errorMessage">Pflichtfeld</span>
              )}
            </div> */}
            <div className="col-lg-12 form-group mt-5 d-flex justify-content-center">
                <input type="submit" className="nav-link btn lpbgColor"></input>
            </div>
        </form>
      </main>
    </>
  );
};

export default AddManufacturer;
